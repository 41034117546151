.aboutContainer {
  padding: 2em;
  max-width: 800px;
  margin: auto;
  text-align: left;
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  .socialLinks {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    gap: 2em;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .socialLink {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      margin-top: 0.5em;
      text-decoration: none;
      font-size: 1rem;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

    .facebookIcon {
      color: #3b5998;
    }
    .twitterIcon {
      color: #1da1f2;
    }
    .instagramIcon {
      color: #e4405f;
    }
    .tiktokIcon {
      color: #000000;
    
        width: 24px;
        height: 24px;
      
    }

    .facebookText {
      color: #3b5998;
    }
    .twitterText {
      color: #1da1f2;
    }
    .instagramText {
      color: #e4405f;
    }
    .tiktokText {
      color: #000000;
    }
  }
}


.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  background-color: #f1f1f1;
  border-top: 1px solid #ccc;
}

.footerText {
  margin: 0;
  font-size: 14px;
  color: #333;
  max-width: 450px;
}

.footerLinks {
  display: flex;
  margin: 0;
  font-size: 14px;
  padding: 5px;
  a {
    color: #007bff;
    text-decoration: none;
    margin-left: 10px;
  }
  a:hover {
    text-decoration: underline;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    a {
      margin-left: 0;
      margin-top: 5px;
    }
  }
}