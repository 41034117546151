.wrapper {
  margin: 2em 0 0 0;
  padding: 2px;
  .title {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 21px;
    color: #4d4d4d;
    margin: 0 0.75rem;
  }
  p {
    color: #757575;
    line-height: 1.5;
    margin: 1rem 0.75rem 0;
    font-size: 15px;
  }
}

.card {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  justify-content: flex-end;
  height: 50px;
  width: 100%;
  border-radius: 5px;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  span {
    position: absolute;
    bottom: 10px;
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 3px;
    font-size: 14px;
    text-align: center;
  }
}

.card:hover::after {
  display: block;
  height: 100%;
  width: 100%;
  background-color: #2b2b2b7c;
  background: linear-gradient(
    360deg,
    #1c1c1c96 0%,
    #7a7a7a3d 33%,
    #ffffff00 100%
  );
  border-radius: 10px;
}

.card:hover {
  transform: scale(1.05) translateY(-0.5em);
  box-shadow: -12px 0 20px -12px #00000059, 12px 0 20px -12px #00000059;
}

.gridContainer {
  padding: 0 3em;
  margin: 2rem -3em 0;
}

.theGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1.5em;
  margin: 1em;
  justify-items: center;
}

@media (max-width: 900px) {
  .wrapper {
    margin: 2em 0.8em 0 0.8em;
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
      text-align: justify;
    }
  }
  .theGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 650px) {
  .theGrid {
    grid-template-columns: 1fr 1fr;
  }
}
