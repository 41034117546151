.tableConatiner {
  padding: 0 3em;
  margin: 0 -3em;
}
.tableConatiner::-webkit-scrollbar {
  width: 0.4em;
}
thead {
  background-color: #f5f5f5; 
  th {
    padding: 10px;
    text-align: left;
    border-bottom: 2px solid #ddd; 
  }
}
.tableConatiner::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.534);
  outline: 1px solid darkgrey;
  border-radius: 15px;
}
.err {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 20vh);
}

.chartTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */


    @media (max-width: 768px) {
      font-size: 1.0rem; /* Medium screens */
    }

    @media (max-width: 480px) {
      font-size: 0.7rem; /* Small screens */
    }
  
}

.shareContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.shareText {
  font-size: 0.45rem; 
  margin-top: 0.25rem; 
}


@media (max-width: 900px) {
  .tableConatiner {
    max-height: calc(100vh - 14vh);
    padding: 0 1rem;
    margin: 0;
    max-width: 100vw;
  }
}
