.profileContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 8px;
    
    /* Flexbox centering */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensures container fills parent height */
    
  }
  
  .profileHeader {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .profileInfo {
    margin-bottom: 10px;
  }
  
  .profileInfo label {
    font-weight: bold;
  }
  
  .profileInfo .value {
    margin-left: 10px;
  }
  
  .profileActions {
    margin-top: 20px;
  }
  
  .profileActions button {
    padding: 10px 20px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .profileActions button:last-child {
    margin-right: 0;
  }
  
  
.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}
.profileIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 20px; 
}

.profile-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.profile-button:hover {
  background-color: #45a049;
}

.editInput {
  //width: 100%;
  padding: 8px;
  margin: 8px 0;
  box-sizing: border-box;
}

.passwordInput {
  //width: 100%;
  padding: 8px;
  margin: 8px 0;
  box-sizing: border-box;
}

.profileActions button {
  margin-right: 10px;
}
.verifyEmail {
  margin-top: 1em;
  text-align: center;
  cursor: pointer;
  color: #007bff;
  font-size: 0.9em;

  &:hover {
    text-decoration: underline;
  }
}
.notVerifiedIcon{
  color: #ff0000d6;
  margin-left: 5px;
}
.verifiedIcon{
  color:#45a049;
  margin-left: 5px;
}
.iconText{
  font-size: 10px;
  color: #d19a4a;
}
.editButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}
.verifyButton {
  background-color: #3528a7;
  color: rgb(255, 255, 255);
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.actionMessage {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.successMessage {
  color: green;
  background-color: #e7f6e7;
  border: 1px solid green;
}

.errorMessage {
  color: red;
  background-color: #fdeaea;
  border: 1px solid red;
}
