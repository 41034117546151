.navWrapper {
  background-color:  #ebedf1;
  background-position: 0 0 0 0;
  background-size: 12px 12px;
  height: fit-content;
  width: inherit;
  box-shadow: 0px 2px 12px #3333338a;
  display: flex;
  flex-direction: row;
  min-width: 220px;
  max-width: 1720px;
  height: 24vh;
  border-radius: 0px;
  align-items: center;
  padding: 0 1em;

  .logoContainer {
    display: flex;
    align-items: center;

    .logo {
      width: 65px;
      margin: 1.5em 0 1rem;
    }

    .siteTitle {
      margin-left: 10px;
      font-size: 1.5rem;
      font-weight: bold;
      color: #000;
    }
  }

  .menu {
    display: none;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 1.2rem;
    width: 100%;

    .icon {
      margin-right: 8px;
      font-size: 2.9rem;
    }

    .username {
      font-weight: bold;
    }
    .username:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 30%;

  a {
    text-decoration: none;
    position: relative;
  }

  li {
    padding: 1em 0;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
    color: #666666;
    transition: 0.4s ease;
  }

  li:hover {
    background-color: #e4e4e480;
  }

  &.open {
    display: flex;
  }

  &.closed {
    display: none;
  }
}

@media (max-width: 900px) {
  .navWrapper {
    z-index: 5;
    position: relative;
    flex-direction: row;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    min-width: 100vw;
    border-radius: 0;

    .logoContainer {
      flex-direction: row;
      align-items: center;
      margin: 0 2em;

      .logo {
        width: 55px;
        margin: 1.5em 0 1rem -2rem;
      }

      .siteTitle {
        font-size: 1rem;
        font-weight: 900;
        margin-left: 10px;
        color: #000;
      }
    }

    .menu {
      display: block;
      width: auto;
      opacity: 1;
      margin: 0 2em;

      img {
        width: 32px;
      }
    }

    

      &.open {
        display: flex;
      }

      &.closed {
        display: none;
      }
    }
  }


@media (min-width: 900px) {
  .container {
    flex-grow: 1;
    font-size: 0.5;
  }
  
  .logoContainer {
    flex-direction: row;
    align-items: center;

    .siteTitle {
      font-size: 0.1rem;
      font-weight: 600;
      color: #1a1919;
    }
  }
}

@media (max-width: 600px) {
  .navWrapper .logoContainer .logo {
    width: 45px;
    margin: 1.5em 0 1rem -2rem;
  }

  .siteTitle {
    font-size: 0.8rem;
    font-weight: 600;
  }
}

@media (min-width: 601px) and (max-width: 899px) {
  .navWrapper .logoContainer .logo {
    width: 55px;
    margin: 1.5em 0 1rem -2rem;
  }

  .navWrapper .logoContainer .siteTitle {
    font-size: 0.9rem;
    font-weight: 600;
  }
}
