.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal {
  background: #fff;
  padding: 10px;
  border-radius: 2px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spotify {
  iframe {
    width: 100%;
    height: 380px;
  }
}
.passwordContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.forgotPassword, .backToLogin {
  margin-top: 1em;
  text-align: right;
  cursor: pointer;
  color: #007bff;
  font-size: 0.9em;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.signupInstead {
  text-align: right;
}

.toggleIcon {
  position: absolute;
  right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #6c757d;
  top: 50%;
  transform: translateY(-50%)
}

.error {
  color: red; 
  font-size: 0.875rem;
  margin-top: 5px;
}

.login {
  width: 99%;
  max-width: 400px;
  margin: 16px auto;
  font-size: 16px;
}

/* Reset top and bottom margins from certain elements */
.login-header,
.login p {
  margin-top: 0;
  margin-bottom: 0;
}

/* The triangle form is achieved by a CSS hack */
.loginTriangle {
  width: 0;
  margin-right: auto;
  margin-left: auto;
  border: 12px solid transparent;
  border-bottom-color: #000;
}

.loginHeader {
  background: #000;
  padding: 10px;
  font-size: 0.9em;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.loginContainer {
  background: #ebebeb;
  padding: 12px;
}

/* Every row inside .login-container is defined with p tags */
.login p {
  padding: 12px;
}

.login input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  padding: 16px;
  outline: 0;
  font-family: inherit;
  font-size: 0.95em;
}

.login input[type="email"],
.login input[type="password"] {
  background: #fff;
  border-color: #bbb;
  color: #555;
}

/* Text fields' focus effect */
.login input[type="email"]:focus,
.login input[type="password"]:focus {
  border-color: #888;
}

.login input[type="submit"] {
  background: #28d;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
}

.login input[type="submit"]:hover {
  background: #17c;
}

/* Buttons' focus effect */
.login input[type="submit"]:focus {
  border-color: #05a;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .login {
    width: 80%;
    font-size: 14px;
  }

  .login input {
    padding: 14px;
    font-size: 0.9em;
  }

  .login input[type="submit"] {
    padding: 14px;
  }
}

@media (max-width: 480px) {
  .login {
    width: 95%;
    font-size: 14px;
  }

  .login input {
    padding: 5px;
    font-size: 0.95em;
  }

  .login input[type="submit"] {
    padding: 5px;
  }
}
