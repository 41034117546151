.container {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.54);
  height: 64vh;
  width: 80vw;
 // min-width: 600px;
  border-radius: 0px;
  margin: 0 0 0 2em;
  overflow: auto;
 //height: calc(100vh - 50px);
}


.container::-webkit-scrollbar {
  width: 0.4em;
}

.container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 215, 0, 0.534); 
  outline: 1px solid #333333; 
  border-radius: 15px;
  
}

.wrapper {
  margin: 0 auto 30px;
  max-width: 90%;
  min-height: fit-content;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(51, 51, 51, 0.54);
}

@media (max-width: 900px) {
  .container {
    width: 100vw;
    border-radius: 0;
    padding-top: 0.5em;
    margin: 20px auto;
    height: calc(100vh - 155px);
  }
  .wrapper {
    width: 95vw;
    //margin: 10px;
   
  }
}

@media (max-width: 560px) {
  .container {
    height: calc(100vh - 166px);
    width: 95vw;
  }
  .wrapper {
    max-width: 97%;
  }
}

@media (max-width: 350px) {
  .wrapper {
    max-width: 100%;
  }
}


@media (min-width: 900px) {
  .container {
    flex-grow: 2;
  }
}

.login-prompt {
  background-color: #333333; 
  color: #ffd700; 
  padding: 8px 12px;
  border: 1px solid #ffd700; 
  border-radius: 4px;
  margin-top: 8px;
}
