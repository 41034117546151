body {
  margin: 0;
  font-family: "Muli", sans-serif;
  background: url('components/assets/images/bg1.jpg') no-repeat center center fixed;
  background-size: cover;
}

.container {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  padding: 0 1.3em;
  margin: 100px auto;
}

.section {
  min-height: calc(100vh - 1em);
  margin: 1.5em 0 0 0;
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.label {
  position: absolute;
  top: 110px; /* Adjusted based on the height of navbar */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(7, 7, 7, 0.7); /* semi-transparent background */
  color: #ffd700;
 // padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2em;
  z-index: 1000; /* Ensure it appears above other elements */
  box-shadow: 0 2px 12px rgba(51, 51, 51, 0.54);
  text-align: center;
  width: inherit;
  
  white-space: normal; 
  word-wrap: break-word; 
}

@media (max-width: 900px) {
  .container {
    padding: 0;
    width: 100vw;
    margin: 70px auto;
  }
  .section {
    min-height: 100vh;
    margin: 10px;
    flex-direction: column;
  }
  .label {
    top: 80px; /* Adjust this based on the height of your navbar */
    font-size: 1em;    
  }
}

@media (max-width: 600px) {
  .container{
    margin: 70px auto;
  }
  .label {
    top: 60px; /* Adjusted this based on the height of navbar */
    font-size: 0.9em;
    p{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media (min-width: 601px) and (max-width: 899px) {
  .label {
    top: 60px; /* Adjusted this based on the height of navbar */
    font-size: 0.9em;
    //padding: 10px 12px;
  }
}

@media (min-width: 900px) {
  .section {
    flex-grow: 1;
  }
}
