$icon-color: #1f1616;

.nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 30%;

  a {
    text-decoration: none;
    position: relative;
  }

  li {
    padding: 1em 0;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
    font-weight: bold;
    color: #1f1616;
    transition: 0.4s ease;
  }

  li:hover {
    background-color: #7c7a7a80;
  }
}

.hamBtn {
  display: flex;
  height: 25px;
  justify-content: space-between;
  flex-direction: column;
  width: 35px;

  div:nth-of-type(1),
  div:nth-of-type(2),
  div:nth-of-type(3) {
    height: 5px;
    width: 35px;
    background: $icon-color;
    transition: 0.3s ease-out;
  }
}

.active {
  div:nth-of-type(1) {
    transform: rotate(42deg) translate(6px, 7px);
  }
  div:nth-of-type(2) {
    transform: translate(50px);
    opacity: 0;
  }
  div:nth-of-type(3) {
    transform: rotate(-42deg) translate(7px, -8px);
  }
}

@media (max-width: 900px) {
  .open {
    opacity: 1;
    transform: translateX(0px);
  }
  .closed {
    transform: translateX(500px);
    opacity: 0;
  }

  .nav {
    position: absolute;
    flex-direction: column;
    transition: 0.4s ease-out;
    top: 100%;
    right: 0;
    background: #ebedf1; 
    backdrop-filter: blur(5px);
    padding: 0 1em;
    border: 1px solid;
    border-radius: 2px;
    height: calc(100vh - 150px);
    width: 35vw;
    transition: 0.2s ease-out;

    li {
      color: #ffffff;
    }

    li:hover {
      color: #c5c5c5;
      background: none;
    }
  }
}

@media (max-width: 450px) {
  .nav {
    li {
      white-space: normal; 
      word-break: break-word;
      //text-align: left;
      font-size: small;
      span{
        display:list-item;
      }
    }
  }
}
