.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2em;
    border-radius: 8px;
    outline: none;
    max-width: 90vw; /* Make sure modal is responsive */
    box-sizing: border-box;
}

.iconContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
  gap: 0.5em; 
}

.iconButton {
  cursor: pointer;
}
  
.iconText {
    font-size: 0.75rem; 
    margin-top: 4px;   
  }
  
@media (max-width: 560px) {
    .iconContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 1em;
    gap: 0.5em; 
  }
}
 