.buttonContainer {
  display: flex;
  flex-direction: row;
 // align-items: center;
 // justify-content: center;
}

.iconButton {
  display: flex;
  flex-direction: column;
  font-size: larger;
 // align-items: center;
  //margin: 0 10px;
}

.iconText {
  font-size: 0.75rem; 
  margin-top: 4px;   
}

@media (max-width: 450px) {
  .iconButton {
    font-size: 0.5rem;
    margin: 0 5px;
  }
  .iconText {
    font-size: 0.7rem;
  }
}

@media (max-width: 600px) {
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 30px;
  }
}

      
