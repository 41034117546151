.adRow {
  background-color: #f9f9f9;
  text-align: center;
}

.adBanner {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.mobileShow {
  display: none;
}

/* AdComponent.css */
.responsive-ad-image {
  max-width: 1000px; 
  max-height: 100px; 
  margin: 0 auto; 
  display: block; 
}

/* Optional: Center-align the ad container */
.adBanner {
  text-align: center;
}

/* Add responsiveness for specific screen sizes */
@media (max-width: 768px) {
  .responsive-ad-image {
    width: 100%; 
    //height: auto;
  }
}

